import {
  stringToUrl,
  flatten,
  makeUniqueIds,
  getQueryParamsFromLocation,
} from '@util';
import filterUnitsBasedOnSearch from './filterUnitsBasedOnSearch';

export function getAvailableUnits(data) {
  const floorplans = data.wagtail.properties
    .filter(property => !property.soldout && property.floorplans.length > 0)
    .map(property => property.floorplans)
    .reduce(flatten, []);
  const floorplanUnits = floorplans.filter(
    floorplan => floorplan.units && floorplan.units.length > 0,
  );
  const availableUnits = floorplanUnits
    .map(unit => {
      const {units} = unit;
      const filteredUnits = units
        .filter(u => u.available)
        .map(availableUnit => {
          const {property} = availableUnit;
          const {address, amenities, name: propertyName} = property;
          const {neighborhood} = address;
          const [{name}] = neighborhood;

          const mergedUnitFloorplan = Object.assign({}, availableUnit, unit);

          const mergedAddress = [
            ...Object.values(address).filter(v => typeof v === 'string'),
          ].join(', ');

          const structuredUrl = `properties/${stringToUrl(
            availableUnit.property.name,
          )}/unit/${availableUnit.slug}/`;

          mergedUnitFloorplan.address = mergedAddress;
          mergedUnitFloorplan.url = structuredUrl;
          mergedUnitFloorplan.amenities = amenities;
          mergedUnitFloorplan.neighborhood = name;
          mergedUnitFloorplan.propertyName = propertyName;

          delete mergedUnitFloorplan.units;
          delete mergedUnitFloorplan.property;

          return mergedUnitFloorplan;
        });
      return filteredUnits;
    })
    .filter(ar => ar.length > 0)
    .reduce(flatten, [])
    .reduce(makeUniqueIds, []);
  return availableUnits;
}

export default function searchUnits(units, location) {
  const {search} = location;
  const queryParams = getQueryParamsFromLocation(search);
  /**
   * if the user is coming from the homepage search or navigates
   * to /search page directly - returning all available units.
   */
  if (
    (Object.keys(queryParams).length === 1 &&
      queryParams.neighborhood === 'all') ||
    !search
  ) {
    return units;
  }

  const queriedUnits = filterUnitsBasedOnSearch(units, queryParams);
  return queriedUnits;
}
