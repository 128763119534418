// @flow

// $FlowFixMe
const removeDashes = (string: string): string => {
  if (string) {
    return string.replace(/-/g, ' ');
  }
};

export default function getNameFromQuery(
  locationObject: Object,
  propName: string,
): string {
  const {neighborhood, minprice, maxprice, amenities, beds} = locationObject;
  switch (propName) {
    case 'neighborhood':
      return neighborhood;
    case 'minprice':
      return `$${minprice}.00`;
    case 'maxprice':
      return `$${maxprice}.00`;
    case 'amenities':
      return `${removeDashes(amenities)}`;
    case 'beds':
      return `${removeDashes(beds)}`;
    default:
      throw new Error('');
  }
}
