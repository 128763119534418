import {stringToUrl} from '@util';

export default function buildUrlQuery(location, nextparams) {
  const filterObject = obj => {
    Object.keys(obj).forEach(
      k =>
        !nextparams[k] && nextparams[k] !== undefined && delete nextparams[k],
    );
    return obj;
  };
  const validQueryObject = filterObject(nextparams);
  const structureQuery = queryObject => {
    const queryObjectEntries = Object.entries(queryObject)
      .map(entry => {
        const filteredPrices = entry
          .map(item => {
            if (item) {
              if (item.includes('$')) {
                return item.replace(/\$/g, '').replace(/\.00/g, '');
              } else {
                return item;
              }
            } else {
              return null;
            }
          })
          .filter(item => item !== undefined && item !== null);
        if (filteredPrices.length > 0) {
          const entryString = filteredPrices.toString();
          const comaToEqual = entryString.replace(/,/g, '=');
          const entryQuery = stringToUrl(comaToEqual);
          return entryQuery;
        } else {
          return null;
        }
      })
      .filter(entry => entry !== undefined && entry !== null);
    const entryQueryString = queryObjectEntries.toString().replace(/,/g, '&');
    const searchString =
      entryQueryString.length > 1 ? `/search/?${entryQueryString}` : `/search/`;
    return searchString;
  };
  location.search = structureQuery(validQueryObject);
  return location.search;
}
