export const priceOptions = [
  {
    id: 1,
    value: '$2000.00',
    text: '$2000.00',
  },
  {
    id: 2,
    value: '$3000.00',
    text: '$3000.00',
  },
  {
    id: 3,
    value: '$4000.00',
    text: '$4000.00',
  },
  {
    id: 4,
    value: '$5000.00',
    text: '$5000.00',
  },
  {
    id: 5,
    value: '$6000.00',
    text: '$6000.00',
  },
  {
    id: 6,
    value: '$7000.00',
    text: '$7000.00',
  },
  {
    id: 7,
    value: '$8000.00',
    text: '$8000.00',
  },
  {
    id: 8,
    value: '$9000.00',
    text: '$9000.00',
  },
  {
    id: 9,
    value: '$10000.00',
    text: '$10000.00',
  },
  {
    id: 10,
    value: '$11000.00',
    text: '$11000.00',
  },
  {
    id: 11,
    value: '$12000.00',
    text: '$12000.00',
  },
];

export const apartmentOptions = [
  {
    id: 1,
    value: 'studio',
    text: 'studio',
  },
  {
    id: 2,
    value: 'one bedroom',
    text: 'one bedroom',
  },
  {
    id: 3,
    value: 'two bedroom',
    text: 'two bedroom',
  },
  {
    id: 4,
    value: 'three bedroom',
    text: 'three bedroom',
  },
  {
    id: 5,
    value: 'four bedroom',
    text: 'four bedroom',
  },
  {
    id: 6,
    value: 'penthouse',
    text: 'penthouse',
  },
];

export const amenitiesOptions = [
  {
    id: 1,
    value: 'concierge',
    text: 'concierge',
  },
  {
    id: 2,
    value: 'fitness center',
    text: 'fitness center',
  },
  {
    id: 3,
    value: 'pet friendly',
    text: 'pet friendly',
  },
  {
    id: 4,
    value: 'resident lounges',
    text: 'resident lounges',
  },
  {
    id: 5,
    value: 'outdoor space',
    text: 'outdoor space',
  },
  {
    id: 6,
    value: 'onsite parking',
    text: 'onsite parking',
  },
];
