import React from 'react';

import Dropdown from '@components/Form/Dropdown';
import dropdownArrow from '@data/svg/DropdownArrow.svg';

type Props = {
  name: string,
  width?: string | number,
  options: Object,
  backgroundColor: string,
  defaultValue: string,
  defaultValueText: string,
  handleChange: (e: Event) => void,
  selectedValue: string,
  mediaQueries: Object,
};

export default ({
  name,
  width,
  options,
  backgroundColor,
  defaultValue,
  defaultValueText,
  handleChange,
  selectedValue,
  mediaQueries,
}: Props): Element<*> => (
  <Dropdown
    name={name}
    width={width ? width : '180px'}
    height="50px"
    padding="0 15px"
    options={options}
    backgroundColor={backgroundColor}
    arrow={dropdownArrow}
    defaultValue={defaultValue}
    defaultValueText={defaultValueText}
    fontSize="15px"
    fontWeight="bold"
    capitalize
    handleChange={e => handleChange(e)}
    selectedValue={selectedValue}
    mediaQueries={mediaQueries}
    smWidth="100%"
    smMargin="10px 0"
  />
);
