import React, {Component, PureComponent} from 'react';
import {Row, Col} from 'jsxstyle';
import {StaticQuery, graphql} from 'gatsby';

import SearchFilterDropdown from './SearchFilterDropdown';
import Button from '@components/Button';
import {
  priceOptions,
  amenitiesOptions,
  apartmentOptions,
} from './searchOptions';
import getNameFromQuery from '@util/getNameFromQuery';
import getNeighborhoodOptions from '@util/getNeighborhoodOptions';
import {ORANGE, WHITE, DARKER_GREY, BLUE} from '@styles/colors';

type Props = {
  searchQuery?: string,
  onSelectChange: () => void,
  onSubmit: () => void,
  onReset: () => void,
};

const parseQueryParamFromEvent = event => {
  const {target} = event;
  const {name, value} = target;
  return {[name]: value};
};

const mediaQueries = {
  sm: 'screen and (max-width: 1200px)',
  lg: 'screen and (min-width: 1280px)',
};

export default class SearchFilters extends PureComponent<Props> {
  handleChange = (e: Event) => {
    if (window) {
      window.resetSearch = false;
    }
    const newParams = parseQueryParamFromEvent(e);
    if (Object.keys(newParams).length > 0) {
      this.props.onSelectChange({...this.props.searchQuery, ...newParams});
    }
  };

  handleSubmit = (e: Event) => {
    e.preventDefault();
    if (window) {
      window.resetSearch = false;
    }
    const newParams = parseQueryParamFromEvent(e);
    if (Object.keys(newParams).length > 0) {
      this.props.onSubmit({...this.props.searchQuery, ...newParams});
    }
  };

  handleReset = (e: Event) => {
    e.preventDefault();
    if (window) {
      window.resetSearch = true;
    }
    this.props.onReset({
      location: null,
    });
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wagtail {
              properties {
                name
                soldout
                address {
                  neighborhood {
                    slug
                    name
                  }
                }
                floorplans {
                  units {
                    available
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const searchOptions = getNeighborhoodOptions(data);
          return (
            <Row
              component="form"
              width="90%"
              margin="0 auto"
              justifyContent="space-between"
              maxWidth="1360px"
              mediaQueries={mediaQueries}
              smFlexDirection="column"
            >
              <SearchFilterDropdown
                name="neighborhood"
                width="350px"
                options={searchOptions}
                backgroundColor={WHITE}
                defaultValueText="All Neighborhoods"
                defaultValue="all"
                handleChange={this.handleChange}
                selectedValue={getNameFromQuery(
                  this.props.searchQuery,
                  'neighborhood',
                )}
                mediaQueries={mediaQueries}
              />
              <Row mediaQueries={mediaQueries} smWidth="100%">
                <SearchFilterDropdown
                  name="minprice"
                  width="180px"
                  options={priceOptions}
                  backgroundColor={DARKER_GREY}
                  defaultValueText="$ Minimum"
                  defaultValue="all"
                  handleChange={this.handleChange}
                  selectedValue={getNameFromQuery(
                    this.props.searchQuery,
                    'minprice',
                  )}
                  mediaQueries={mediaQueries}
                />
                <SearchFilterDropdown
                  name="maxprice"
                  width="180px"
                  options={priceOptions}
                  backgroundColor={WHITE}
                  defaultValueText="$ Maximum"
                  defaultValue="all"
                  handleChange={this.handleChange}
                  selectedValue={getNameFromQuery(
                    this.props.searchQuery,
                    'maxprice',
                  )}
                  mediaQueries={mediaQueries}
                />
              </Row>
              <Row mediaQueries={mediaQueries} smFlexDirection="column">
                <SearchFilterDropdown
                  name="amenities"
                  width="180px"
                  options={amenitiesOptions}
                  backgroundColor={DARKER_GREY}
                  defaultValueText="Amenities"
                  defaultValue="all"
                  handleChange={this.handleChange}
                  selectedValue={getNameFromQuery(
                    this.props.searchQuery,
                    'amenities',
                  )}
                  mediaQueries={mediaQueries}
                />
                <SearchFilterDropdown
                  name="beds"
                  width="180px"
                  options={apartmentOptions}
                  backgroundColor={WHITE}
                  defaultValueText="Number of beds"
                  defaultValue="all"
                  handleChange={this.handleChange}
                  selectedValue={getNameFromQuery(
                    this.props.searchQuery,
                    'beds',
                  )}
                  mediaQueries={mediaQueries}
                />
                <Col mediaQueries={mediaQueries} smWidth="100%" smHeight="auto">
                  <Button
                    buttonLink="#"
                    buttonText="search"
                    buttonColor={ORANGE}
                    buttonHeight="50px"
                    buttonWidth="180px"
                    onClickMethod={e => this.handleSubmit(e)}
                    mediaQueries={mediaQueries}
                    smWidth="100%"
                    smHeight="100%"
                    smMargin="10px 0"
                  />
                  <button
                    style={{
                      color: BLUE,
                      height: 'auto',
                      width: 'auto',
                      WebkitBorderRadius: '0',
                      border: 'none',
                      background: 'transparent',
                      textDecoration: 'underline',
                      textTransform: 'capitalize',
                      fontSize: '15px',
                      fontWeight: 'bold',
                      margin: '15px 0 0 0',
                      cursor: 'pointer',
                    }}
                    onClick={e => this.handleReset(e)}
                  >
                    {`clear all`}
                  </button>
                </Col>
              </Row>
            </Row>
          );
        }}
      />
    );
  }
}
