import React, {Fragment, Component} from 'react';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';
import {Row} from 'jsxstyle';
import {Location, navigate} from '@reach/router';
import queryString from 'query-string';

import Layout from '@layouts';
import Section from '@components/layout/Section';
import CardGrid from '@components/CardGrid';
import Headline from '@components/Headline';
import Paragraph from '@components/Paragraph';
import Divider from '@components/Divider';
import Link from '@components/Link';
import SearchFilters from '@components/Search/SearchFilters';
import SectionTitle from '@components/SectionTitle';
import {ORANGE, BLUE, WHITE, LIGHT_GREY} from '@styles/colors';
import searchUnits, {getAvailableUnits} from '@util/searchUnits';
import buildUrlQuery from '@util/buildUrlQuery';

import type {PropertyType, AvailableUnitType} from '@types-local';

type Props = {
  data: {
    wagtail: {
      ...PropertyType[],
    },
  },
};

type State = {
  availableUnits: AvailableUnitType[],
};

const getValueFromQuery = (location: Object) => {
  const {search} = location;
  return queryString.parse(search);
};

const handleSearchFilterChange = location => nextparams => {
  navigate(buildUrlQuery(location, nextparams));
};

export default class Search extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      availableUnits: getAvailableUnits(props.data),
    };
  }
  render() {
    const {data} = this.props;
    return (
      <Fragment>
        <Helmet>
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
          <meta name="description" content={data.site.siteMetadata.meta} />
        </Helmet>
        <Layout menuItemColor={BLUE} menuBackground={WHITE}>
          <Location>
            {({location}) => {
              const excludedProperties = ['298 Mulberry', 'The Victory', '345E94'];
              const excludedNeighborhoods = ['Upper East Side', 'Hell\'s Kitchen', 'NoHo'];
              const queriedUnits = searchUnits(
                this.state.availableUnits,
                location,
              )

              const filteredUnits = queriedUnits.filter(unit =>
                unit.propertyName &&
                !excludedProperties.includes(unit.propertyName) &&
                unit.address &&
                unit.neighborhood &&
                !excludedNeighborhoods.includes(unit.neighborhood)
              );
              console.log({stateAvailableUnits: this.state.availableUnits, queriedUnits, filteredUnits})
              
              return (
                <Fragment>
                  <Section padding="190px 0 70px 0" background={LIGHT_GREY}>
                    <Headline color={BLUE} textAlign="center">
                      Find the perfect home for you.
                    </Headline>
                    <SearchFilters
                      searchQuery={getValueFromQuery(location)}
                      onSelectChange={handleSearchFilterChange(location)}
                      onSubmit={handleSearchFilterChange(location)}
                      onReset={handleSearchFilterChange(location)}
                    />
                  </Section>
                  <Section>
                    <Row
                      maxWidth="1360px"
                      width="90%"
                      margin="0 auto"
                      justifyContent="space-between"
                      padding="0 20px"
                    >
                      <SectionTitle
                        textAlign="left"
                        alignItems="flex-start"
                        margin="0"
                        width="70%"
                      >
                        <Headline textAlign="left">
                          {`We found ${queriedUnits.length} apartments for you`}
                        </Headline>
                        <Divider />
                        <Paragraph color={ORANGE} textAlign="left">
                          {'Not finding what you are looking for? Call us at '}
                          <Link
                            href={`tel: 1-212-427-9700`}
                            style={{
                              color: ORANGE,
                              fontWeight: 'bold',
                              textDecoration: 'underline',
                            }}
                          >
                            212.427.9700
                          </Link>
                        </Paragraph>
                      </SectionTitle>
                    </Row>
                  </Section>
                  <Section>
                    <CardGrid
                      data={queriedUnits}
                      maxWidth="1360px"
                      gridTemplateColumns={
                        'repeat(auto-fit, minmax(400px, 1fr))'
                      }
                      gridGap="40px"
                      margin="0 auto"
                    />
                  </Section>
                </Fragment>
              );
            }}
          </Location>
        </Layout>
      </Fragment>
    );
  }
}

export const query = graphql`
  query {
    wagtail {
      properties {
        id
        soldout
        name
        floorplans {
          id
          name
          beds
          baths
          squareFeet
          salesforceId
          isStudio
          isPenthouse
          image {
            url
          }
          units {
            id
            slug
            price
            available
            availableDate
            property {
              name
              amenities {
                description
              }
              address {
                street
                city
                state
                postalCode
                neighborhood {
                  name
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        meta
      }
    }
  }
`;
