// @flow

import {filterArrayOfObjects} from '@util';
import type {AvailableUnitType} from '@types-local';

type QueryParams = {
  neighborhood: string,
  minprice: string,
  maxprice: string,
  beds: string,
  amenities: string,
};

function normalizeNeighborhoodQuery(neighborhoodQuery: string): string | null {
  switch (neighborhoodQuery) {
    case 'nomad':
      return 'NoMad';
    case 'midtown-south':
      return 'Midtown South';
    case 'upper-west-side':
      return 'Upper West Side';
    case 'upper-east-side':
      return 'Upper East Side';
    case 'midtown-west':
      return 'Midtown West';
    case 'hells-kitchen':
      return "Hell's Kitchen";
    case 'noho':
      return "NoHo";
    case 'all':
      return 'all';
    default:
      return null;
  }
}

function normalizeBedsQuery(bedsQuery: string): string | number | null {
  switch (bedsQuery) {
    case 'studio':
      return 0;
    case 'one-bedroom':
      return 1;
    case 'two-bedroom':
      return 2;
    case 'three-bedroom':
      return 3;
    case 'four-bedroom':
      return 4;
    case 'penthouse':
      return 'penthouse';
    case 'all':
      return 'all';
    default:
      return null;
  }
}

/**
 * Given two strings, function splits the first string on words
 * and then looks for those words within the second string.
 * Returns true if the second string contains one of the words from
 * the first string.
 */

function isStringInString(
  stringToParse: string,
  stringToTraverse: string,
): boolean {
  const words = stringToParse
    .replace(/-/g, ' ')
    .trim()
    .split(' ');
  const matching = [];
  words.forEach(word => {
    if (stringToTraverse.toLowerCase().includes(word)) {
      matching.push(stringToTraverse);
    }
  });
  return Boolean(matching.length > 0);
}

const cachedUnits: AvailableUnitType[] = [];

function neighborhoodMatches(normalizedNeighborhood, unitNeighborhood) {
  return Boolean(
    normalizedNeighborhood === unitNeighborhood ||
      normalizedNeighborhood === 'all',
  );
}

function bedsMatches(normalizedBedsVal, unitBeds, isPenthouse) {
  return Boolean(
    (isPenthouse && normalizedBedsVal === 'penthouse') ||
      normalizedBedsVal === unitBeds ||
      normalizedBedsVal === 'all',
  );
}

function priceWithinRange(unitPrice, minprice, maxprice) {
  if (minprice || maxprice) {
    if (minprice && maxprice) {
      if (minprice === 'all' && maxprice === 'all') {
        return true;
      } else if (minprice === 'all' && unitPrice < Number(maxprice)) {
        return true;
      } else if (maxprice === 'all' && unitPrice > Number(minprice)) {
        return true;
      } else if (unitPrice > Number(minprice) && unitPrice < Number(maxprice)) {
        return true;
      } else {
        return false;
      }
    } else if (minprice) {
      if (unitPrice > Number(minprice) || minprice === 'all') {
        return true;
      } else {
        return false;
      }
    } else if (maxprice) {
      if (unitPrice < Number(maxprice) || maxprice === 'all') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}

function amenitiesMatch(queryAmenities, unitAmenities) {
  return Boolean(
    unitAmenities.some(amenity =>
      isStringInString(queryAmenities, amenity.description),
    ) || queryAmenities === 'all',
  );
}

export default function filterUnitsBasedOnSearch(
  availableUnits: AvailableUnitType[],
  locationObject: QueryParams,
) {
  const {
    minprice,
    maxprice,
    neighborhood: queryNeighborhood,
    beds: queryBeds,
    amenities: queryAmenities,
  }: QueryParams = locationObject;

  const normalizedBedsVal = normalizeBedsQuery(queryBeds);
  const normalizedNeighborhood = normalizeNeighborhoodQuery(queryNeighborhood);

  function filterUnits(units) {
    const unitsToFilter = cachedUnits.length > 0 ? cachedUnits : units;
    return unitsToFilter.filter(availableUnit => {
      const {beds, price, amenities, neighborhood} = availableUnit;
      if (
        queryNeighborhood &&
        queryBeds &&
        (minprice || maxprice) &&
        queryAmenities
      ) {
        return (
          neighborhoodMatches(normalizedNeighborhood, neighborhood) &&
          bedsMatches(normalizedBedsVal, beds) &&
          priceWithinRange(price, minprice, maxprice) &&
          amenitiesMatch(queryAmenities, amenities)
        );
      } else if (queryNeighborhood && queryBeds && (minprice || maxprice)) {
        return (
          neighborhoodMatches(normalizedNeighborhood, neighborhood) &&
          bedsMatches(normalizedBedsVal, beds) &&
          priceWithinRange(price, minprice, maxprice)
        );
      } else if (
        queryNeighborhood &&
        queryAmenities &&
        (minprice || maxprice)
      ) {
        return (
          neighborhoodMatches(normalizedNeighborhood, neighborhood) &&
          amenitiesMatch(queryAmenities, amenities) &&
          priceWithinRange(price, minprice, maxprice)
        );
      } else if (queryBeds && queryAmenities && (minprice || maxprice)) {
        return (
          bedsMatches(normalizedBedsVal, beds) &&
          amenitiesMatch(queryAmenities, amenities) &&
          priceWithinRange(price, minprice, maxprice)
        );
      } else if (queryBeds && queryAmenities && queryNeighborhood) {
        return (
          bedsMatches(normalizedBedsVal, beds) &&
          amenitiesMatch(queryAmenities, amenities) &&
          neighborhoodMatches(normalizedNeighborhood, neighborhood)
        );
      } else if (queryNeighborhood && queryBeds) {
        return (
          neighborhoodMatches(normalizedNeighborhood, neighborhood) &&
          bedsMatches(normalizedBedsVal, beds)
        );
      } else if (queryNeighborhood && queryAmenities) {
        return (
          neighborhoodMatches(normalizedNeighborhood, neighborhood) &&
          amenitiesMatch(queryAmenities, amenities)
        );
      } else if (queryNeighborhood && (minprice || maxprice)) {
        return (
          neighborhoodMatches(normalizedNeighborhood, neighborhood) &&
          priceWithinRange(price, minprice, maxprice)
        );
      } else if (queryBeds && (minprice || maxprice)) {
        return (
          priceWithinRange(price, minprice, maxprice) &&
          bedsMatches(normalizedBedsVal, beds)
        );
      } else if (queryBeds && queryAmenities) {
        return (
          priceWithinRange(price, minprice, maxprice) &&
          amenitiesMatch(queryAmenities, amenities)
        );
      } else if (queryAmenities && (minprice || maxprice)) {
        return (
          priceWithinRange(price, minprice, maxprice) &&
          bedsMatches(normalizedBedsVal, beds)
        );
      } else if (minprice || maxprice) {
        return priceWithinRange(price, minprice, maxprice);
      } else if (queryAmenities) {
        return amenitiesMatch(queryAmenities, amenities);
      } else if (queryBeds) {
        return bedsMatches(normalizedBedsVal, beds);
      } else if (queryNeighborhood) {
        return neighborhoodMatches(normalizedNeighborhood, neighborhood);
      } else {
        return true;
      }
    });
  }

  const filteredUnits = filterUnits(availableUnits);

  /**
   * removes objects with duplicate ids.
   */
  const uniqueFilteredUnits = filterArrayOfObjects(filteredUnits, 'id');

  return uniqueFilteredUnits;
}
